import React, { useState, forwardRef, useCallback, useEffect, useContext } from 'react';
// import { FixedSizeList as List } from 'react-window';
import { fetchModelList } from '../api/modelAPI.js';
import { detectLanguage } from './detectlang.js';
import { AceEditorComponent } from './AceSpaceBro.js';
import { RenderCopyToClipboardButton } from './copyclip.js';
import DataTable from './ChatDataTable.js';
import { getCookie, setCookie } from '../cookieUtils.js';
import './Dashboard.css';
import { log } from '../utils/logger.js';
import { AuthContext }  from '../App.js'


export function ChatContainer({ isDarkMode, isMobile, windowWidth, Ampersandposium, display, reloadData, showDelete, userEmail, wait, styles, setNotificationMessage }, ref) {
  const { vectorVault } = useContext(AuthContext);
  const [chatMessage, setChatMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isChatLoading, setChatIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [getContext, setGetContext] = useState(true);
  const [nContext, setNContext] = useState(4);
  const [historySearch, setHistorySearch] = useState(false);
  const [model, setModel] = useState(getCookie('model') ? getCookie('model') : 'gpt-4o');
  const [prevModel, setPrevModel] = useState(getCookie('model') ? getCookie('model') : 'gpt-4o');
  const [tempMessage, setTempMessage] = useState('');
  const [models, setModels] = useState([]);
  const [customModelName, setCustomModelName] = useState('');
  const [showCustomModelInput, setShowCustomModelInput] = useState(model === 'finetuned');

  // Load the state from local storage when the component mounts
  useEffect(() => {
    const storedChatHistory = localStorage.getItem("chatHistory") || "[]";
    // Load the chat history from local storage
    try {
      const parsedChatHistory = JSON.parse(storedChatHistory);
      setChatHistory(parsedChatHistory);
    } catch (error) {
      console.error("Failed to parse stored chat history:", error);
    }
  }, []);
  
  // Load OpenAI models
  useEffect(() => {
    async function loadModels() {
      const fetchedModels = await fetchModelList(userEmail);
      setModels(fetchedModels);
    }
    loadModels();
  }, [userEmail]);
  

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    setModel(selectedModel);

    if (selectedModel === 'finetuned') {
      setShowCustomModelInput(true);
    } else {
      setShowCustomModelInput(false);
      setCustomModelName(''); // Clear custom model name when not needed
    }
  };

  const getSelectedModel = () => {
    if (model === 'finetuned') {
      return customModelName.trim(); // Use the user-input model name
    } else {
      return model; // Use the selected predefined model
    }
  };

  
  // for the fixed window
  const Row = ({ index, style }) => {
    const item = chatHistory[index];
    return (
      <div style={style}>
        {renderChatSegment(item.message, index, item.isUserMessage)}
      </div>
    );
  };
  
  const getItemSize = (index) => {
    const item = chatHistory[index];
    const numberOfLines = item.message.split('\n').length;
    const lineHeight = 20; // Adjust this based on your styling
    const padding = 10; // Adjust this based on your styling
    return numberOfLines * lineHeight + padding;
  };

  useEffect(() => {
    if ( model !== prevModel ) {
      const yo = `Using model: ${model}`
      setCookie('model', model)
      setPrevModel(model)
      setNotificationMessage(yo)
    }
  }, [model]);

  const copyToClipboard = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }
  
  const showChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(true);
  }, [setChatIsLoading]);
  
  const hideChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(false);
  }, [setChatIsLoading]);
  
  const appendChatMessage = useCallback((message, isUserMessage) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { message, isUserMessage },
    ]);
  }, [setChatHistory]);

  const removeLastChatMessage = useCallback(() => {
    setChatHistory((prevHistory) => {
      // Check if the last message is a user message
      if (prevHistory.length > 0 && !prevHistory[prevHistory.length - 1].isUserMessage) {
        // Remove the last item from the array if it's not a user message
        const updatedHistory = prevHistory.slice(0, prevHistory.length - 1);
        return updatedHistory;
      }
      // Return the original chat history if the last message is a user message
      return prevHistory;
    });
  }, [setChatHistory]);
  
  function renderChatSegment(segment, index, isUserMessage) {
    const ram = '```';
    if (segment.startsWith(ram) && segment.endsWith(ram)) {
      let code = segment.slice(3, -3);
      const { language, code: codeWithoutSpecification, isSpecified } = detectLanguage(code);
      // console.log(language, code, codeWithoutSpecification, isSpecified)
      
      // Use the code without the specification line if a language was specified
      if (isSpecified) {
        code = codeWithoutSpecification;
      }
  
      const type = language;
      
      const increaseBy = 1/(windowWidth/500) * 6
      
      return (
        <div key={index} style={{ position: 'relative', width: `10${increaseBy}%`, marginLeft: '-13px', }}>
        <label>{type}</label>
        <AceEditorComponent code={code} type={type} isDarkMode={isDarkMode} />
        {RenderCopyToClipboardButton(code, isDarkMode, isCopied, setIsCopied, copyToClipboard)}
    </div>
      );
    } else {
      return (
        <div key={index} id="chat-history" style={isUserMessage ? styles.userMessage : styles.botMessage}>
          {segment}
        </div>
      );
    }
  }
  

  const sendChatMessage = async () => {
    if (!chatMessage) return;
    appendChatMessage(chatMessage, true);
    showChatLoadingSpinner();
    let pastChatMessage = chatMessage
    let metatag, metatag_prefixes, metatag_suffixes, returnContext;
    setChatMessage('');
    
    if (pastChatMessage.trim().endsWith('**')) {
      metatag = ['item_id'];
      metatag_prefixes = ['\n**Item ID:'];
      metatag_suffixes = ['\n'];
      returnContext = true;
    } else {
      metatag = [];
      metatag_prefixes = [];
      metatag_suffixes = [];
      returnContext = false;
    }

    const chatModel = getSelectedModel()
    
    let fullHistory = chatHistory.map((item) => {
      return item.isUserMessage ? `User: ${item.message}` : `Response: ${item.message}`;
    }).join('\n');

    log('full history:', fullHistory);

    try {
      // Determine if the selected vault is a flow
      let isFlow = false;
      let vaultName = Ampersandposium; 
  
      if (vaultName.startsWith('Flow: ')) {
        isFlow = true;
        vaultName = vaultName.substring('Flow: '.length); // Remove "Flow: " prefix
      }
  
      if (isFlow) {
        // Prepare parameters for runFlowStream
        const flowName = vaultName;
        const message = pastChatMessage;
        const history = fullHistory;
  
        // Call runFlowStream with callbacks
        await vectorVault.runFlowStream(flowName, message, history, {
          onMessage: (dataChunk) => {
            // Handle each chunk of data received from the stream
            if (dataChunk === '!END') {
              // End of stream; update chat history
              setTempMessage((tempMessage) => {
                const newChatHistory = [
                  ...chatHistory,
                  { message: pastChatMessage, isUserMessage: true },
                  { message: tempMessage, isUserMessage: false },
                ];
                localStorage.setItem('chatHistory', JSON.stringify(newChatHistory));
                setChatHistory(newChatHistory);
                return '';
              });
              hideChatLoadingSpinner();
            } else {
              // Append incoming data to the temporary message and update the UI
              setTempMessage((tempMessage) => {
                let updatedTempMessage = `${tempMessage}${dataChunk}`;
                removeLastChatMessage();
                appendChatMessage(updatedTempMessage, false);
                return updatedTempMessage;
              });
            }
          },
          onLog: (logData) => {
            // Handle log data if necessary
            console.log('Log:', logData);
          },
          onError: (error) => {
            // Handle errors
            appendChatMessage(error.message, false);
            localStorage.setItem(
              'chatHistory',
              JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }])
            );
            hideChatLoadingSpinner();
          },
        });
      } else {
        // Prepare parameters for getChatStream
        const params = {
          vault: vaultName,
          text: pastChatMessage,
          history: fullHistory,
          get_context: getContext,
          n_context: nContext,
          return_context: returnContext,
          smart_history_search: historySearch,
          model: chatModel,
          metatag: metatag,
          metatag_prefixes: metatag_prefixes,
          metatag_suffixes: metatag_suffixes,
          // Additional parameters if needed
        };
  
        // Call getChatStream method
        await vectorVault.getChatStream(params, (dataChunk) => {
          // Handle each chunk of data received from the stream
          if (dataChunk === '!END') {
            // End of stream; update chat history
            setTempMessage((tempMessage) => {
              const newChatHistory = [
                ...chatHistory,
                { message: pastChatMessage, isUserMessage: true },
                { message: tempMessage, isUserMessage: false },
              ];
              localStorage.setItem('chatHistory', JSON.stringify(newChatHistory));
              setChatHistory(newChatHistory);
              return '';
            });
            hideChatLoadingSpinner();
          } else {
            // Append incoming data to the temporary message and update the UI
            setTempMessage((tempMessage) => {
              let updatedTempMessage = `${tempMessage}${dataChunk}`;
              removeLastChatMessage();
              appendChatMessage(updatedTempMessage, false);
              return updatedTempMessage;
            });
          }
        });
      }
    } catch (error) {
        // Handle errors by appending the error message to the chat
        appendChatMessage(error.message, false);
        localStorage.setItem("chatHistory", JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }]));
    }
  };

  
  const clearChat = useCallback(() =>  {
    setChatHistory([])
    localStorage.removeItem("chatHistory");
  }, [setChatHistory]);
  
  const handleTextareaKeydown = (e) => {
    if  (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      sendChatMessage();
    }
  };
  
  return (
    <>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: "20px"  }}>
            
            {/* Spacer element */}
            <div style={{ flexGrow: 1 }}></div>

            <button 
                id="clear-chat" 
                className="link-button" 
                onClick={clearChat} 
                style={{ marginTop: "0px", marginRight: "2px" }}
            >
                Clear
            </button>
          </div>
        </div>

          <div style={{ marginTop: "10px" }}></div>
          {chatHistory.flatMap((item, index) => {
            const segments = item.message.split(/(```[\s\S]*?```)/);
            return segments.map((segment, segmentIndex) =>
              renderChatSegment(segment, `${index}-${segmentIndex}`, item.isUserMessage)
            );
          })}
          <div style={{ display: 'flex', alignItems: 'center', margin: "5px" }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.yourmsgLabel }}>
                <label htmlFor="chat-message">Chat:</label>
              </div>
              <select
                style={{ marginLeft: "10px", marginTop: "20px" }}
                className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                value={model}
                onChange={handleModelChange}
              >
                {models.map((modelItem) => (
                  <option key={modelItem.model} value={modelItem.model}>
                    {modelItem.model}
                  </option>
                ))}
                <option value="finetuned">Finetuned Model</option>
              </select>
              {showCustomModelInput && (
                <input
                  type="text"
                  placeholder="Enter fine-tuned model name"
                  value={customModelName}
                  onChange={(e) => setCustomModelName(e.target.value)}
                  style={{ ...styles.apiInput, marginLeft: "10px", marginTop: "20px"  }}
                />
              )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input 
                type="checkbox" 
                id="vault-reply" 
                style={{ marginLeft: "15px", marginTop: "22px" }}
                onChange={(e) => setGetContext(e.target.checked)}
                defaultChecked
                title="Check to use Vault context in the reply (default). Uncheck to use the LLM without any Vault context."
              />
              <label style={{ ...styles.text, marginLeft: "6px", marginTop: "22px", fontSize: ".9rem" }} htmlFor="vault-reply" title="Check to use Vault context in the reply (default). Uncheck to use the LLM without any Vault context.">
                get context
              </label>
            </div>
          </div>
        <textarea //
          placeholder={`Type your message here...`}
          id="chat-message"
          rows={isMobile ? "8" : "5" }
          style={{ ...styles.textarea, paddingLeft: "8px"}}
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}> 
          <button id="chat-submit" style={{ ...styles.button }} onClick={sendChatMessage}>Send</button>
        </div>
        <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isChatLoading ? 'block' : 'none', paddingLeft: '20px'  }}>
          <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
          </svg>
        </div>
        <div style={{ marginTop: "100px" }}></div>
        {display && <DataTable Ampersandposium={Ampersandposium} reloadData={reloadData} showDelete={showDelete} userEmail={userEmail} isDarkMode={isDarkMode} wait={wait} styles={styles} />}
      </div>
    </>
  );
}

export default forwardRef(ChatContainer);