// Sidebar.js
import React, { useEffect } from 'react';
import logo from '../Vector Vault Icon.png';
import { useNavigate } from 'react-router-dom';
import { billingPortal } from '../api/billingPortal';
import Orientation from '../dashboard/Orient';
import { setCookie } from '../cookieUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Sidebar({ isDarkMode, setIsDarkMode, styles, sidebarVisible, setSidebarVisible, customerID, isMobile }) {
    const isLandscape = Orientation('(orientation: landscape)')
    const sidebarClass = isDarkMode ? 'sidebar sidebar-dark' : 'sidebar sidebar-light';
    const navigate = useNavigate();
    const payingCustomer = customerID !== "null";
    const handleBillingClick = async () => {
        if (!payingCustomer) {
            window.open('https://billing.vectorvault.io/b/7sIeYu1Rr15j0sU8wy', '_blank');
        } else {
            await billingPortal(customerID); // Pass the customerID to the billing portal function
        }
    };

    useEffect(() => {
        if (isMobile) {
          setSidebarVisible(false);
        } else {
          setSidebarVisible(true);
        }
      }, [isMobile, setSidebarVisible]);

    const style = isLandscape ? { 
        position: 'fixed', 
        top: '10px', 
        left: sidebarVisible ? '164px' : '0', 
        zIndex: 1000, cursor: 'pointer', 
        transition: 'left 0.3s', 
        transform: 'translateY(-50%)',
        marginLeft: '-8px',
        background: 'transparent', 
        marginTop: 'calc(4%)' 
    } : { 
            position: 'fixed', 
            top: '10px', 
            left: sidebarVisible ? '164px' : '0', 
            zIndex: 1000, cursor: 'pointer', 
            transition: 'left 0.3s', 
            transform: 'translateY(-50%)',
            marginLeft: '-8px',
            background: 'transparent',
            marginTop: 'calc(7%)' };


    const toggleIfObble = () => {
        if (isMobile) {
            setSidebarVisible(false)
        }
        else {
            setSidebarVisible(true)
        }
    }

    const handleDashboardClick = () => {
        toggleIfObble()
        navigate('/dashboard');
    };

    const handleDatabaseClick = () => {
        toggleIfObble()
        navigate('/database');
    };

    const handleFlowClick = () => {
        toggleIfObble()
        navigate('/vector-flow');
    };

    const handleDeploymentsClick = () => {
        toggleIfObble()
        navigate('/deployments');
    };
    
    const handleVaultsClick = () => {
        toggleIfObble()
        navigate('/vaults');
    };

    const handleApiKeysClick = () => {
        toggleIfObble()
        navigate('/apikeys');
    };
    
    const handleUsageClick = () => {
        toggleIfObble()
        navigate('/usage');
    };
    
    const handleSettingsClick = () => {
        toggleIfObble()
        navigate('/settings');
    };

    useEffect(() => {
        // Check for dark mode setting in localStorage on component mount
        const savedMode = localStorage.getItem("isDarkMode");
        if (savedMode) {
            setIsDarkMode(savedMode === 'true');
        }
    }, [setIsDarkMode]);

    const toggleTheme = () => {
        const newState = !isDarkMode;
        setIsDarkMode(newState);
        localStorage.setItem("isDarkMode", newState.toString());
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
        setCookie('sidebarVisible', String(!sidebarVisible));
    };

    return (
        <div className={`sidebar-container ${isMobile && !sidebarVisible ? 'hide-sidebar' : ''}`} >
            <div className={sidebarClass} style={{ ...styles.body, width: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh', padding: '20px', position: sidebarVisible ? 'relative' : 'fixed', left: 0, top: 0 }}>
                <div>
                    <h1 style={{ ...styles.logo, textAlign: "center", fontSize: "1rem", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "8px", paddingRight: "0px", marginLeft: "-6px"}}>
                        <img src={logo} className="App-logo" alt="logo" style={{ height: "3.5rem" }} />
                    </h1>
                        <div style={{ marginTop: "4px", fontSize: "1.2rem", justifyContent: "center", display: "flex", marginLeft: "-6px"}}>Vector Vault</div>
                </div>
                <div className={`sidebar-nav ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                    <ul>
                        <li onClick={handleDashboardClick}>Dashboard</li>
                        <li onClick={handleDatabaseClick}>Database</li>
                        <li onClick={handleDeploymentsClick}>Deployments</li>
                        <li onClick={handleFlowClick}>Vector Flow</li>
                        <li onClick={handleVaultsClick}>Vaults</li>
                        <li onClick={handleUsageClick}>Usage</li>
                        <li>{payingCustomer ? <a className="no-style-link" href="#" onClick={handleBillingClick}>Billing </a> : <a className="no-style-link" href="#" onClick={handleBillingClick}>SignUp </a> }</li>
                        <li onClick={handleApiKeysClick}>API Keys</li>
                        <li onClick={handleSettingsClick}>Settings</li>
                    </ul>
                </div>
                <div className="sidebar-footer">
                    <button className={`toggle-button ${isDarkMode ? "dark-mode" : ""}`} onClick={toggleTheme}>
                        {isDarkMode ? "Dark Mode" : "Light Mode"}
                    </button>
                </div>
            </div>
            {isMobile && (
                <div 
                className={isDarkMode ? "sidebar-tab-dark" : "sidebar-tab-light"} 
                onClick={toggleSidebar} 
                style={{ ...style }}
                >
                <FontAwesomeIcon icon={faBars} />
                </div>
            )}
        </div>
    );
}

export default Sidebar;
